<template>
  <div class="px-5 _bg-default min">
    <section class="indigo pa-2 rounded d-flex mb-3">
      <img src="../../assets/img/penilaian.svg" height="100px" />
      <div>
        <h1 class="white--text ml-3">Penilaian</h1>
      </div>
    </section>
    <v-card>
      <v-tabs
        v-model="tab"
        class="head-tab"
        centered
        icons-and-text
        background-color="indigo"
        dark
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Tugas Individu
          <v-icon>mdi-file-outline</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          Tugas Kelompok
          <v-icon>mdi-file-multiple-outline</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat class="pa-2">
            <FeedbackIndividu />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card flat class="pa-2">
            <FeedbackKelompok />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import FeedbackIndividu from "../../components/General/Feedback/feebackIndividu";
import FeedbackKelompok from "../../components/General/Feedback/feebackKelompok";
export default {
  name: "feedback",
  components: {
    FeedbackIndividu,
    FeedbackKelompok
  },
  data() {
    return {
      tab: null
    };
  },
  computed: {
    id() {
      return localStorage.getItem("id");
    }
  }
};
</script>

<style scoped>
._bg-default {
  background: #f4f6f9;
}
.min {
  min-height: 90vh;
}
</style>

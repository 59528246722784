<template>
  <div>
    <v-row class="mt-5">
      <v-progress-linear
        :active="loadTable"
        :indeterminate="loadTable"
        absolute
        top
        color="orange"
      ></v-progress-linear>
      <v-col cols="12" md="2" class="pb-0 mb-0"
        ><label for="event">Pilih Pelatihan</label></v-col
      >
      <v-col cols="12" md="10" class="pb-0 mb-5" v-if="allPelatihan">
        <v-select
          :options="allPelatihan.data"
          :reduce="(nama) => nama.id"
          v-model="select_pelatihan"
          @search="(query) => (find = query)"
          label="nama"
          outlined
          @input="get_submission(select_pelatihan)"
        ></v-select>
      </v-col>
    </v-row>
    <v-card class="temp-table mt-5" v-if="submissionY">
      <v-card-title>
        List Tugas
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Cari"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <div>
        <v-data-table :headers="headers" :items="submissionY" :search="search">
          <template v-slot:[`item.file`]="{ item }">
            <v-btn
              color="indigo"
              :href="`${env}/upload/tugas_individu/${item.file}`"
              target="_blank"
              dark
              >{{ item.file }} <v-icon>mdi-download</v-icon></v-btn
            >
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ moment(item.created_at).format("DD MMMM YYYY") }}
          </template>
          <template v-slot:[`item.score`]="{ item }">
            <v-text-field
              placeholder="beri nilai"
              v-model="item.nilai_objektif_teacher"
              :id="`qhk${item.id}rv`"
              class="my-1"
              solo
              dense
              type="number"
              style="width: 100px"
              hide-details
              v-if="role == 2"
            ></v-text-field>
            <v-text-field
              placeholder="beri nilai"
              v-model="item.nilai_objektif_mentor"
              :id="`qhk${item.id}rv`"
              class="my-1"
              solo
              dense
              style="width: 100px"
              type="number"
              hide-details
              v-if="role == 3"
            ></v-text-field>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-btn dark color="cyan" class="mr-2" @click="nilai(item)">
                Nilai
                <v-icon dark class="ml-2"> mdi-pencil </v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-dialog v-model="progress" hide-overlay persistent width="250">
      <v-card class="indigo" dark>
        <v-card-text>
          silahkan tunggu...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_EVENT } from "../../../graphql/Event";
import { EDIT_STUDENT } from "../../../graphql/Student";
import moment from "moment";
import Swal from "sweetalert2";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { between, numeric } from "vuelidate/lib/validators";
export default {
  apollo: {
    allPelatihan: {
      query: GET_EVENT,
      variables() {
        return { page: 1, first: 10, search: this.find };
      },
      error(err) {
        console.log(err);
      },
    },
  },
  mixins: [validationMixin],
  validations: {
    nilai_objektif_mentor: { between: between(1, 100), numeric },
    nilai_objektif_teacher: { between: between(1, 100), numeric },
  },
  components: {
    vSelect,
  },
  name: "feedbackIndividu",
  computed: {
    ...mapState(["submissionY", "userProfile"]),
    loadTable() {
      return this.$apollo.queries.allPelatihan.loading;
    },
  },
  data() {
    return {
      allPelatihan: [],
      env: process.env.VUE_APP_GRAPHQL,
      role: localStorage.getItem("role_id"),
      select_pelatihan: null,
      find: "",
      event: null,
      moment: moment,
      progress: false,
      search: "",
      dialogView: false,
      score: null,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Nama", value: "nama_lengkap" },
        { text: "Kelompok", value: "nama_grup" },
        { text: "Dokumen", value: "file" },
        {
          text: "Tanggal Unggah",
          align: "start",
          value: "created_at",
        },
        { text: "Nilai", value: "score" },
        { text: "Aksi", value: "actions" },
      ],
    };
  },

  methods: {
    nilai(item) {
      let reg = /^\d+$/;
      let role = localStorage.getItem("role_id");
      if (role == 2) {
        let teacher = reg.test(item.nilai_objektif_teacher);
        if (teacher) {
          if (
            item.nilai_objektif_teacher <= 100 &&
            item.nilai_objektif_teacher >= 1
          ) {
            this.updatePeserta(item);
          } else {
            this.swalErr("error", "Nilai hanya dari 1 sampai 100");
          }
        } else {
          this.swalErr("error", "Nilai harus berisi angka");
        }
      } else {
        let mentor = reg.test(item.nilai_objektif_mentor);
        let nilaiM = item.nilai_objektif_mentor;
        if (mentor) {
          if (nilaiM <= 100 && nilaiM >= 1) {
            this.updatePeserta(item);
          } else {
            this.swalErr("error", "Nilai hanya dari 1 sampai 100");
          }
        } else {
          this.swalErr("error", "Nilai harus berisi angka");
        }
      }
    },

    updatePeserta(item) {
      this.progress = true;
      const id = parseInt(item.user_id);
      const id_profile = parseInt(item.id);
      const nilai_objektif_teacher = item.nilai_objektif_teacher;
      const nilai_objektif_mentor = item.nilai_objektif_mentor;
      const tipe_avatar = this.userProfile.tipe_avatar;
      this.$apollo
        .mutate({
          mutation: EDIT_STUDENT,
          variables: {
            id: id,
            connect: id,
            id_profile: id_profile,
            nilai_objektif_teacher: nilai_objektif_teacher,
            nilai_objektif_mentor: nilai_objektif_mentor,
            tipe_avatar: tipe_avatar,
          },
        })
        .then(() => {
          this.progress = false;
          Swal.fire({
            icon: "success",
            title: "Penilaian berhasil",
            showConfirmButton: false,
            timer: 3000,
            position: "top",
            toast: true,
          });
        })
        .catch((err) => {
          console.log(err.graphQLErrors[0].message);
          this.progress = false;
          Swal.fire({
            icon: "error",
            title: err.graphQLErrors[0].message,
            confirmButtonColor: "#3F51B5",
            confirmButtonText: "ok",
            position: "top",
            toast: true,
          });
        });
    },

    swalErr(type, isi) {
      Swal.fire({
        icon: type,
        title: isi,
        confirmButtonColor: "#3F51B5",
        confirmButtonText: "ok",
        position: "top",
        toast: true,
      });
    },
    get_submission(id) {
      this.src = false;
      this.$store.dispatch("listSubmissionY", { id: id });
      console.log(id);
    },
  },
};
</script>

<style scopd></style>

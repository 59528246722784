var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-5"},[_c('v-progress-linear',{attrs:{"active":_vm.loadTable,"indeterminate":_vm.loadTable,"absolute":"","top":"","color":"orange"}}),_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12","md":"2"}},[_c('label',{attrs:{"for":"event"}},[_vm._v("Pilih Pelatihan")])]),(_vm.allPelatihan)?_c('v-col',{staticClass:"pb-0 mb-5",attrs:{"cols":"12","md":"10"}},[_c('v-select',{staticClass:"white",attrs:{"options":_vm.allPelatihan.data,"reduce":function (nama) { return nama.id; },"label":"nama","outlined":""},on:{"search":function (query) { return (_vm.find = query); },"input":function($event){return _vm.get_submission(_vm.select_pelatihan)}},model:{value:(_vm.select_pelatihan),callback:function ($$v) {_vm.select_pelatihan=$$v},expression:"select_pelatihan"}})],1):_vm._e()],1),(_vm.submissionX)?_c('v-card',{staticClass:"temp-table"},[_c('v-card-title',[_vm._v(" List Tugas "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Cari","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.submissionX,"search":_vm.search},scopedSlots:_vm._u([{key:"item.file",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"indigo","href":(_vm.env + "/upload/tugas_grup/" + (item.file)),"target":"_blank","dark":""}},[_vm._v(_vm._s(item.file)+" "),_c('v-icon',[_vm._v("mdi-download")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("DD MMMM YYYY"))+" ")]}},{key:"item.score",fn:function(ref){
var item = ref.item;
return [(_vm.role == 2)?_c('v-text-field',{staticClass:"my-1",staticStyle:{"width":"100px"},attrs:{"placeholder":"beri nilai","id":("qhk" + (item.id) + "rv"),"solo":"","dense":"","type":"number","hide-details":""},model:{value:(item.nilai_objektif_teacher),callback:function ($$v) {_vm.$set(item, "nilai_objektif_teacher", $$v)},expression:"item.nilai_objektif_teacher"}}):_vm._e(),(_vm.role == 3)?_c('v-text-field',{staticClass:"my-1",staticStyle:{"width":"100px"},attrs:{"placeholder":"beri nilai","id":("qhk" + (item.id) + "rv"),"solo":"","dense":"","type":"number","hide-details":""},model:{value:(item.nilai_objektif_mentor),callback:function ($$v) {_vm.$set(item, "nilai_objektif_mentor", $$v)},expression:"item.nilai_objektif_mentor"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","color":"cyan"},on:{"click":function($event){return _vm.nilai(item)}}},[_vm._v(" Nilai "),_c('v-icon',{staticClass:"ml-2",attrs:{"dark":""}},[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)})],1)],1):_vm._e(),_c('v-dialog',{attrs:{"hide-overlay":"","persistent":"","width":"250"},model:{value:(_vm.progress),callback:function ($$v) {_vm.progress=$$v},expression:"progress"}},[_c('v-card',{staticClass:"indigo",attrs:{"dark":""}},[_c('v-card-text',[_vm._v(" silahkan tunggu... "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }